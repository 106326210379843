/* eslint-disable no-undef */
import React, { useEffect } from "react"
import HeadlineIcon from "../modules/HeroMedia/variants/HeadlineIcon"
import { fireEvent } from "../utils/helpers"

import notfound from "../assets/images/404page.svg"
import CountryList, { Card } from "../modules/CountryList/CountryList"
import { graphql, Link, useStaticQuery } from "gatsby"
import Container from "../components/containers/Container"

// markup
const TermsAndConditions = () => {
  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  const {
    craft: { entries },
  } = useStaticQuery(graphql`
    query TermsAndConditionsPages {
      craft {
        entries(section: "termsAndConditionsPage") {
          ... on craft_termsAndConditionsPage_default_Entry {
            id
            slug
            title
            richText
            isFeatured
            country {
              ... on craft_countries_info_Entry {
                title
              }
            }
            termsAndConditionsTags {
              ... on craft_termsAndConditionsTags_tags_BlockType {
                documentLanguage
                isDefault
              }
            }
          }
        }
      }
    }
  `)

  return (
    <main>
      <HeadlineIcon
        content={{
          identifier: "not-found",
          lightBackground: "green-background",
          headlineIcon: [
            {
              headline: "Terms & Conditions",
              icon: [{ url: notfound }],
              buttons: [{ linkAddress: [{ text: "Read more", address: "#list", actionType: "anchor" }] }],
            },
          ],
        }}
      />
      <CountryList content={{ identifier: "list" }} pages={entries} />
      <Container className="flex flex-col justify-center items-center">
        <div className="w-full mb-14" style={{ maxWidth: 932 }}>
          <h4 className="text-blue font-extended">Supplier</h4>
        </div>
        <div className="flex flex-col md:flex-row md:flex-wrap justify-start items-center gap-6 xl:gap-8 w-full" style={{ maxWidth: 932 }}>
          {entries
            .filter(e => !e.country[0])
            .map(card => {
              return (
                <Link key={card.title} to={"/" + card.slug} className="w-full md:w-fit">
                  <Card className="flex flex-col items-start justify-between rounded-xl bg-green-background px-5 py-4 shadow-none hover:shadow-dropdown transition-shadow w-full md:w-72">
                    <h6 className="font-extended text-p2 text-blue font-extrabold text-left">{card.title}</h6>
                  </Card>
                </Link>
              )
            })}
        </div>
      </Container>
    </main>
  )
}

export default TermsAndConditions
